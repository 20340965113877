import { MessageCircleIcon } from "lucide-react";
import {
  useState,
  type ReactNode,
  createContext,
  useEffect,
  useCallback,
  useContext,
} from "react";

const ChatbotContext = createContext({
  showChatbotButton: false,
  useShowChatbot: () => {},
  openChatbot: () => {},
});

export function useChatbotContext() {
  const context = useContext(ChatbotContext);
  if (!context) {
    throw new Error(
      "Chatbot component must be rendered within the ChatbotContext component",
    );
  }
  return context;
}

export function useHelpScout(options?: {
  onOpen?: () => void;
  onClose?: () => void;
}) {
  const [beaconLoaded, setBeaconLoaded] = useState(false);

  useEffect(() => {
    function loadHelpScout() {
      const scriptElement = document.getElementsByTagName("script")[0];
      const newScript = document.createElement("script");
      newScript.type = "text/javascript";
      newScript.async = true;
      newScript.src = "https://beacon-v2.helpscout.net";
      scriptElement.parentNode?.insertBefore(newScript, scriptElement);

      newScript.onload = () => {
        if (window.Beacon) {
          window.Beacon("on", "ready", () => setBeaconLoaded(true));

          if (options?.onOpen) {
            window.Beacon("on", "open", options.onOpen);
          }

          if (options?.onClose) {
            window.Beacon("on", "close", options.onClose);
          }

          window.Beacon("init", "4f580aef-4b0e-40c0-8053-57d55a65c7a3");
        }
      };
    }

    if (typeof window !== "undefined" && !window.Beacon) {
      window.Beacon = function (method, options, data) {
        if (window.Beacon && window.Beacon.readyQueue) {
          window.Beacon.readyQueue.push({ method, options, data });
        }
      };
      window.Beacon.readyQueue = [];

      if (document.readyState === "complete") {
        loadHelpScout();

        // window.Beacon("config", {
        //   display: {
        //     horizontalMobileOffset: -400,
        //     verticalMobileOffset: -400,
        //   },
        // });
      } else {
        window.addEventListener("load", loadHelpScout, false);
      }
    }
  }, [options?.onClose, options?.onOpen]);

  return { beaconLoaded };
}

export function ChatbotProvider({ children }: { children: ReactNode }) {
  const [showChatbot, setShowChatbot] = useState(false);
  const { beaconLoaded } = useHelpScout({
    // onOpen: () => setShowChatbot(false),
    // onClose: () => setShowChatbot(true),
  });

  const useShowChatbot = () => {
    useEffect(() => {
      setShowChatbot(true);

      return () => setShowChatbot(false);
    }, []);
  };

  const openChatbot = useCallback(() => {
    if (window.Beacon && beaconLoaded) {
      window.Beacon("open");
    }
  }, [beaconLoaded]);

  const contextValue = {
    showChatbotButton: beaconLoaded && showChatbot,
    useShowChatbot,
    openChatbot,
  };

  return (
    <ChatbotContext.Provider value={contextValue}>
      {children}
    </ChatbotContext.Provider>
  );
}

export function Chatbot() {
  const { openChatbot, showChatbotButton } = useChatbotContext();

  return showChatbotButton ? (
    <button
      className="fixed bottom-5 right-5 z-50 rounded-lg bg-brand-mint_green-800 px-4 py-1 text-white md:py-2"
      onClick={openChatbot}
    >
      <div className="flex items-center gap-1">
        <MessageCircleIcon className="h-4 w-4" />
        <span>Need help?</span>
      </div>
    </button>
  ) : null;
}
